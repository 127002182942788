<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import Fuse from 'fuse.js';
import { getNode } from '@formkit/core';
import type { ClientWithDetails, ClientWithDetailsE } from '~/types/clients';

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndSmaller = breakpoints.smallerOrEqual('md');

const user = useUser();

withDefaults(
  defineProps<{
    isCollasped?: boolean;
  }>(),
  {
    isCollasped: false,
  }
);

const loadingClients = ref(true);
const isMobile = computed(() => mdAndSmaller.value);

const {
  clients: clientMap,
  listExpire,
  currentClient,
} = storeToRefs(useClientStore());

if (listExpire.value === null || listExpire.value < new Date()) {
  // fetch the clients from the server and cache the list for 20 mins
  useHFetch<ClientWithDetails[]>(`/api/clients`).then((newClients) => {
    clientMap.value = new Map(
      newClients.map((client) => [
        client.slug,
        {
          ...client,
          // client details expire after 10 mins
          expire: new Date(Date.now() + 10 * 60 * 1000),
        },
      ])
    );
    listExpire.value = new Date(Date.now() + 20 * 60 * 1000);
    fuse = new Fuse(clients.value, {
      keys: ['name', 'slug'],
      threshold: 0.2,
    });
    loadingClients.value = false;
  });
} else loadingClients.value = false;

const clients = computed(() => {
  if (!clientMap.value) return [];
  return Array.from(clientMap.value.values());
});

const clientDropdown = ref();
const searchClientsInputVal = ref('');

const dropdownOpened = () => {
  searchClientsInputVal.value = '';
  activeIndex.value = -1;
  setTimeout(() => {
    const search = getNode(
      isMobile.value ? 'searchClientsInputMobile' : 'searchClientsInput'
    );
    if (search) {
      search.context?.fns.focus();
    }
  }, 200);
};

let fuse = new Fuse(clients.value, {
  keys: ['name', 'slug'],
  threshold: 0.2,
});

const filteredClients = computed((): ClientWithDetailsE[] => {
  if (!clients.value) return [];
  if (!searchClientsInputVal.value) return clients.value;
  return fuse.search(searchClientsInputVal.value).map((r) => r.item);
});

const activeIndex = ref(-1);

watch(searchClientsInputVal, (newVal) => {
  if (newVal !== '') activeIndex.value = 0;
});

const updateIndex = (delta: 1 | -1) => {
  if (filteredClients.value.length > 0) {
    const targetIndex = activeIndex.value + delta;
    if (targetIndex >= 0 && targetIndex < filteredClients.value.length) {
      activeIndex.value = targetIndex;
    }
  }
};

const submitSearch = (clientIndex?: number) => {
  if (filteredClients.value.length > 0) {
    if (
      filteredClients.value[clientIndex ?? activeIndex.value].slug !==
      currentClient.value?.slug
    ) {
      navigateTo(
        `/client/${
          filteredClients.value[clientIndex ?? activeIndex.value].slug
        }`
      );
    } else if (clientDropdown.value) {
      clientDropdown.value.closeDropdown();
    }
  }
};

const escapeSearch = () => {
  if (searchClientsInputVal.value) {
    searchClientsInputVal.value = '';
  } else if (clientDropdown.value) {
    clientDropdown.value.closeDropdown();
  }
};

// const mobileModalOpen = ref(false);
// const openMobileModal = () => {
//   mobileModalOpen.value = true;
//   dropdownOpened();
// };

const initials = computed(() => {
  if (currentClient.value && currentClient.value.name)
    return currentClient.value.name.length < 3
      ? currentClient.value.name.toUpperCase()
      : currentClient.value.name.substring(0, 2).toUpperCase();
  return '';
});

const fetchClientNotifs = () => api(`/api/notifications/clients`);
const { data: clientNotifications } = useQuery({
  queryKey: ['client-notifications'],
  queryFn: fetchClientNotifs,
  staleTime: 1000 * 60 * 5,
});
</script>
<template>
  <HDropdown
    ref="clientDropdown"
    align="right"
    :type="2"
    :offset="-30"
    class="w-full"
    :with-search="true"
    @open="dropdownOpened"
  >
    <div class="cc-card">
      <img
        v-if="!isCollasped && currentClient?.avatarUrl"
        class="cc-thumb object-cover object-center rounded-lg"
        :src="imageAssetSrc(currentClient?.avatarUrl)"
      />
      <div v-else class="cc-thumb">
        <span class="px-1.5 py-[9px] text-sm">{{ initials }}</span>
      </div>

      <div class="w-full inline-flex justify-between items-center">
        <span
          class="text-ht-monochrome-bw text-base font-medium whitespace-nowrap truncate"
        >
          {{ isCollasped ? '' : (currentClient?.name ?? 'Select client...') }}
        </span>

        <HIcon
          icon="NavArrowRight"
          class="w-[18px] h-[18px] text-ht-text-gray-1"
        />
      </div>
    </div>

    <template #content>
      <div
        class="pb-11 bg-ht-primary-bg-fill shadow flex-col justify-start items-start gap-4 inline-flex md:-mt-5"
        :class="{
          'w-[500px] h-[480px] drop-shadow-hub-dropdown-light dark:drop-shadow-hub-dropdown-dark':
            !isMobile,
          'w-full': isMobile,
        }"
      >
        <div
          class="w-full h-[55px] bg-ht-primary-bg-fill-90 bg-opacity-80 border-b border-ht-border-1 justify-between items-center inline-flex"
          :class="{
            'px-4': !isMobile,
            'px-2': isMobile,
          }"
        >
          <div
            class="w-[407.50px] h-4 justify-start items-center gap-4 inline-flex"
          >
            <HIcon icon="Search" class="w-4 h-4 md:hidden" />
            <FormKit
              id="searchClientsInput"
              v-model="searchClientsInputVal"
              type="text"
              label="Search clients"
              placeholder="Search clients"
              autocomplete="off"
              outer-class="md:px-1"
              @keyup.enter="submitSearch(undefined)"
              @keyup.esc="escapeSearch"
              @keyup.down="updateIndex(1)"
              @keyup.up="updateIndex(-1)"
            ></FormKit>
          </div>
        </div>

        <div
          class="w-full flex justify-between items-center"
          :class="{
            'px-4': !isMobile,
            'px-2 md:px-3': isMobile,
          }"
        >
          <div class="h-[26px] justify-start items-center gap-2.5 inline-flex">
            <span
              class="text-ht-hub-text-1 text-base font-medium leading-relaxed"
            >
              Clients
            </span>
            <HTag :clickable="false" :fill="true">{{
              filteredClients.length
            }}</HTag>
          </div>

          <HButton
            v-if="useAccess(user, 'AG_CLIENTS', 'E')"
            variant="tertiary"
            @click="navigateTo('/agency/clients')"
            >Manage</HButton
          >
        </div>

        <div
          class="w-full flex-col justify-start items-start gap-1 inline-flex overflow-y-auto"
          :class="{
            'px-4': !isMobile,
            'px-1 md:px-3': isMobile,
          }"
        >
          <template v-if="loadingClients">
            <CardClientRow
              v-for="i in 3"
              :key="`skeleton-${i}`"
              :loading="true"
            ></CardClientRow>
          </template>
          <CardClientRow
            v-for="(client, index) in filteredClients"
            v-else
            :key="client.id"
            :client="client"
            :show-check-box="false"
            :class="{
              'bg-ht-cc-rc-bg-hover': index == activeIndex,
            }"
            :notifications="clientNotifications?.[client.slug]?.total"
            @click="submitSearch(index)"
          />
        </div>
      </div>
    </template>
  </HDropdown>
  <!-- <div v-else>
    <div class="cc-card py-2 items-center" @click="openMobileModal">
      <img
        v-if="!isCollasped && currentClient?.avatarUrl"
        class="cc-thumb object-cover object-center rounded-lg"
        :src="imageAssetSrc(currentClient?.avatarUrl)"
      />
      <div v-else class="cc-thumb m-0 self-center">
        <span class="px-1.5 py-[9px] text-sm">{{ initials }}</span>
      </div>

      <span
        class="w-[70%] text-ht-monochrome-bw text-base font-medium whitespace-nowrap truncate"
      >
        {{ isCollasped ? '' : currentClient?.name }}
      </span>

      <HIcon
        icon="NavArrowRight"
        class="w-[18px] h-[18px] text-ht-text-gray-1"
      />
    </div>

    <HModal
      v-if="mdAndSmaller"
      v-model="mobileModalOpen"
      :show-controls="false"
      :with-search="true"
    >
      <template #search>
        <FormKit
          id="searchClientsInputMobile"
          v-model="searchClientsInputVal"
          type="text"
          label="Search clients"
          placeholder="Search clients"
          autocomplete="off"
          @keyup.enter="submitSearch(undefined)"
          @keyup.esc="escapeSearch"
          @keyup.down="updateIndex(1)"
          @keyup.up="updateIndex(-1)"
        ></FormKit>
      </template>
      <div
        class="w-full pb-11 pt-5 flex-col justify-start items-start gap-4 inline-flex"
      >
        <div class="w-full px-4 flex justify-between items-center">
          <div
            class="w-24 h-[26px] justify-start items-center gap-2.5 inline-flex"
          >
            <span
              class="text-ht-hub-text-1 text-base font-medium leading-relaxed"
            >
              Clients
            </span>
            <HTag :clickable="false" :fill="true">{{
              filteredClients.length
            }}</HTag>
          </div>

          <HButton variant="tertiary">Manage</HButton>
        </div>

        <div
          class="w-full px-4 flex-col justify-start items-start gap-1 inline-flex"
        >
          <template v-if="loadingClients">
            <span>Loading clients...</span>
          </template>
          <CardClientRow
            v-for="(client, index) in filteredClients"
            v-else
            :key="client.id"
            :client="client"
            :show-check-box="false"
            :class="{
              'bg-ht-cc-rc-bg-hover': index == activeIndex,
            }"
            @click="submitSearch(index)"
          />
        </div></div
    ></HModal>
  </div> -->
</template>

<style lang="scss" scoped>
.cc-card {
  @apply overflow-hidden cursor-pointer transition-all duration-300 ease-hub-ease;
  @apply w-full pl-2.5 pr-3 py-3 bg-ht-cc-rc-bg-fill bg-opacity-20 rounded-[13px] border border-ht-border-1 justify-start items-center gap-3 inline-flex;
  @apply hover:bg-ht-cc-rc-bg-hover;

  &.checked {
    @apply bg-ht-btn-gray-hover border-ht-border-3;
  }
}

.cc-thumb {
  @apply w-8 h-8 bg-ht-btn-gray rounded-[6px] border border-ht-border-2 flex-col justify-center items-center gap-2.5 inline-flex;
  @apply text-ht-text-gray-1 text-sm font-semibold leading-[1];
}
</style>
