<script setup lang="ts">
// Importing the HyptTLogoMenu for the menu icon
import HyptTLogoMenuBlack from '~/assets/svg/logo_menu_black.svg';
import HyptTLogoMenuWhite from '~/assets/svg/logo_menu_white.svg';

// Setting up the store and route for state management and navigation
// const store = useModuleStore();
// const { currentCreative: creative, currentProject: project } =
//   storeToRefs(store);
const modalOpen = ref<boolean>(false); // State for the modal visibility

const route = useRoute(); // Using the route for navigation

// Watching the route change to close the modal
watch(
  () => route.fullPath,
  () => {
    modalOpen.value = false; // Close the modal on route change
  }
);

const { currentClient } = storeToRefs(useClientStore());

const initials = computed(() => {
  if (currentClient.value && currentClient.value.name)
    return currentClient.value.name.length < 3
      ? currentClient.value.name.toUpperCase()
      : currentClient.value.name.substring(0, 2).toUpperCase();
  return '';
});
</script>

<template>
  <div
    class="hidden w-full h-12 px-4 py-[10px] bg-ht-primary-bg-fill-90 border-b border-ht-border-1 justify-between items-center md:flex"
  >
    <div class="justify-start items-center gap-2 inline-flex">
      <!-- <HIcon icon="Menu" class="w-5 h-5" /> -->
      <!-- <HButton
        variant="plain"
        icon-left="Menu"
        class="w-[30px] h-[30px] px-1"
        @click="modalOpen = !modalOpen"
      /> -->

      <!-- Slide over for the menu -->
      <HSlideOver
        v-model="modalOpen"
        title="Menu"
        :show-header="false"
        :show-controls="false"
        side="left"
        width="max-w-fit md:max-w-fit"
        :ui="{
          background: 'bg-black/10 dark:bg-black/10',
        }"
        @click.self="modalOpen = !modalOpen"
      >
        <div
          class="h-screen flex flex-col justify-start items-start"
          @click.self="modalOpen = !modalOpen"
        >
          <HPrimarySidebar
            :is-mobile="true"
            class="h-screen"
            @close="modalOpen = false"
          />
        </div>
      </HSlideOver>

      <!-- Logo for the menu -->
      <HyptTLogoMenuBlack
        v-if="$colorMode.preference === 'light'"
        class="fill-ht-hub-text-1 w-[80px] hidden md:block"
        @click="modalOpen = !modalOpen"
      />
      <HyptTLogoMenuWhite
        v-else
        class="fill-ht-hub-text-1 w-[80px] hidden md:block"
        @click="modalOpen = !modalOpen"
      />
    </div>

    <div
      v-if="currentClient"
      class="justify-start items-center gap-0.5 inline-flex"
    >
      <div class="justify-start items-center gap-2 inline-flex">
        <span
          class="hidden w-full text-ht-text-gray-1 text-opacity-50 text-sm font-medium leading-snug truncate md:block"
        >
          {{ currentClient?.name }}
        </span>

        <img
          v-if="currentClient?.avatarUrl"
          class="cc-thumb object-cover object-center rounded-lg"
          :src="imageAssetSrc(currentClient?.avatarUrl)"
        />
        <div v-else class="cc-thumb">
          <span class="px-1.5 py-[9px] text-sm">{{ initials }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cc-thumb {
  @apply w-7 h-7 bg-ht-btn-gray rounded-[6px] border border-ht-border-2 flex-col justify-center items-center gap-2.5 inline-flex;
  @apply text-ht-text-gray-1 text-sm font-semibold leading-[1];
}
</style>
